<!-- 进销存动表 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <FormItem>
        <span>单据时间：</span>
        <span>{{ topData.create_time }}</span>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>单据编号：</span>
        <span>{{ topData.order_number }}</span>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>单据类型：</span>
        <span>{{ typeStr }}</span>
      </FormItem>
      <span class="pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
      <span class="pageBtn finger btnReset marginLeft20" @click="back">返回</span>
    </Form>
    <div class="clearfix tabDiv">
      <Table :productList="listColumns" :loading="Loading" :isLoad="Loading" :option_page="option_page" :productData="listData" highlight-row border :pages="pages" :total="total" totalText="条记录" @change-page="changePage" :TotalQuantity="TotalQuantity"></Table>
      <!-- <div class="pages" v-if="listData !== null && listData.length > 0 && total">
        <Page v-if="total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl" />
        <Page v-if="total > 60" :total="total" :current="pages.page" @on-change="changePage" show-elevator class="fl" />
        <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
      </div> -->
      <div class="fr totalFont marginRight30" v-if="!total">
        合计： <span>{{ total_number }}</span>
      </div>
    </div>

    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>

<script>
import Table from '@/components/table'
import TableSetup from '@/components/tableSetup'
export default {
  name: 'purchaseAndSaleSecendDetailConsign',
  components: {
    Table,
    TableSetup,
  },
  data() {
    return {
      Loading: true,
      id: '',
      type: 1, // 1入库 2出库
      urlName: '',
      typeStr: '',
      setupStatus: false,
      titleList: [],
      option_page: '-1',
      topData: {},
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0, // 项目条数
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 75,
        },
        {
          title: '日期',
          key: 'a',
          align: 'center',
          width: 75,
        },
        {
          title: '单据编号',
          key: 'a',
          align: 'center',
          width: 75,
        },
        {
          title: '单据类型',
          key: 'a',
          align: 'center',
          width: 75,
        },
        {
          title: '产品编号',
          key: 'a',
          align: 'center',
          width: 75,
        },
        {
          title: '产品名称',
          key: 'a',
          align: 'center',
          width: 75,
        },
        {
          title: '规格型号',
          key: 'a',
          align: 'center',
          width: 75,
        },
        {
          title: '入库数量',
          key: 'a',
          align: 'center',
          width: 75,
        },
        {
          title: '出库数量',
          key: 'a',
          align: 'center',
          width: 75,
        },
      ],
      TotalQuantity: [],
    }
  },
  created() {
    this.id = this.$route.query.id
    this.type = this.$route.query.type
    this.typeStr = this.$route.query.typeStr
    if (this.type == 1) {
      this.option_page = '30'
      this.urlName = 'inputOrderRecodeDetail'
      this.getDetail()
    } else {
      this.queryList1()
      this.option_page = '31'
      this.urlName = 'warehouseDeliveryDetail'
    }
    this.queryOption()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 分页
    changePage(e) {
      this.pages.page = e
      if (this.type == 1) {
        this.getDetail()
      } else {
        this.queryList1()
      }
    },
    // 获取表格表头
    queryOption() {
      this.$http.get(this.$apiConsign.systemOptionItem, { option_page: this.option_page }).then(res => {
        this.titleList = res.data.enable
        localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
        for (let i = 0; i < this.titleList.length; i++) {
          if (this.titleList[i].show) {
            this.titleList[i].key = this.titleList[i].data_col
            this.titleList[i].width = this.titleList[i].column_width
            this.titleList[i].align = 'center'
            this.titleList[i].resizable = true
          }
        }
        this.listColumns = []
        this.listColumns.unshift({
          title: '序号',
          type: 'index',
          align: 'center',
          width: 60,
        })
        this.listColumns = [...this.listColumns, ...this.titleList]
        this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
      })
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.create_time_start = e
        that.queryFrom.create_time_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.create_time_start)
          },
        }
      } else {
        this.queryFrom.create_time_end = e
      }
    },
    // 出库
    queryList1() {
      let obj = {
        id: this.id,
        page: this.pages.page,
        rows: this.pages.rows,
      }
      this.Loading = true
      this.$http.get(this.$apiConsign.warehouseDeliveryDetail, obj, true).then(res => {
        this.topData = res.data
        this.listData = res.data.warehouse_delivery_id
        this.total_number = res.data.total_number
        this.total_amount = res.data.total_amount

        this.TotalQuantity.push({ title: '合计', total: res.data.total_number })
        // 处理日期
        this.topData.create_time = this.topData.create_time ? this.$moment(this.topData.create_time * 1000).format('YYYY-MM-DD') : ''
        this.Loading = false
        this.listData.forEach(item => {
          item.licence_valid_period = item.licence_valid_period ? this.$moment.unix(item.licence_valid_period).format('YYYY-MM-DD') : ''
          item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD HH:mm:ss') : ''
          // 判断是录入的是年月还是年月日
          item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date) : ''
          item.valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD HH:mm:ss') : ''
          item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period) : ''
          item.production_license_valid_period = item.production_license_valid_period ? this.$moment.unix(item.production_license_valid_period).format('YYYY-MM-DD') : ''
          item.production_record_date = item.production_record_date ? this.$moment.unix(item.production_record_date).format('YYYY-MM-DD') : ''
          if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
            item.business_license_valid_period = item.business_license_valid_period ? this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') : ''
          } else if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
            item.business_license_valid_period = '长期'
          } else {
            item.business_license_valid_period = ''
          }
          if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
            item.record_valid_period = item.record_valid_period ? this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') : ''
          } else if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
            item.record_valid_period = '长期'
          } else {
            item.record_valid_period = ''
          }
          item.return_time = item.return_time ? this.$moment.unix(item.return_time).format('YYYY-MM-DD') : ''
          item.receive_time = item.receive_time ? this.$moment.unix(item.receive_time).format('YYYY-MM-DD') : ''
          item.accept_time = item.accept_time ? this.$moment.unix(item.accept_time).format('YYYY-MM-DD') : ''
          item.rec_time = item.rec_time ? this.$moment.unix(item.rec_time).format('YYYY-MM-DD') : ''
          item.order_time = item.order_time ? this.$moment.unix(item.order_time).format('YYYY-MM-DD') : ''
          item.picking_time = item.picking_time ? this.$moment.unix(item.picking_time).format('YYYY-MM-DD') : ''
          item.delivery_time = item.delivery_time ? this.$moment.unix(item.delivery_time).format('YYYY-MM-DD') : ''
        })
        console.log(this.topData, 'this.topData')
      })
    },
    // 入库
    getDetail() {
      let obj = {
        warehouse_receipt_id: this.id,
        receipt_page: 1,
        page: this.pages.page,
        rows: this.pages.rows,
      }
      this.Loading = true
      this.$http.get(this.$apiConsign.inputOrderRecodeDetail, obj, true).then(res => {
        if (res.status) {
          console.log(res.data.return_dict.quantity_total, '98789789789')
          this.TotalQuantity.push({ title: '合计', total: res.data.return_dict.quantity_total })
          this.Loading = false
          for (const item of res.data.return_dict.result) {
            item.licence_valid_period = item.licence_valid_period ? this.$moment.unix(item.licence_valid_period).format('YYYY-MM-DD') : ''
            item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD') : ''
            item.valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD') : ''
            item.production_license_valid_period = item.production_license_valid_period ? this.$moment.unix(item.production_license_valid_period).format('YYYY-MM-DD') : ''
            item.production_record_date = item.production_record_date ? this.$moment.unix(item.production_record_date).format('YYYY-MM-DD') : ''
            if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
              item.business_license_valid_period = item.business_license_valid_period ? this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') : ''
            } else if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
              item.business_license_valid_period = '长期'
            } else {
              item.business_license_valid_period = ''
            }
            if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
              item.record_valid_period = item.record_valid_period ? this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') : ''
            } else if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
              item.record_valid_period = '长期'
            } else {
              item.record_valid_period = ''
            }
            item.return_time = item.return_time ? this.$moment.unix(item.return_time).format('YYYY-MM-DD') : ''
            item.receive_time = item.receive_time ? this.$moment.unix(item.receive_time).format('YYYY-MM-DD') : ''
            item.accept_time = item.accept_time ? this.$moment.unix(item.accept_time).format('YYYY-MM-DD') : ''
            item.rec_time = item.rec_time ? this.$moment.unix(item.rec_time).format('YYYY-MM-DD') : ''
            item.order_time = item.order_time ? this.$moment.unix(item.order_time).format('YYYY-MM-DD') : ''
            item.picking_time = item.picking_time ? this.$moment.unix(item.picking_time).format('YYYY-MM-DD') : ''
            item.delivery_time = item.delivery_time ? this.$moment.unix(item.delivery_time).format('YYYY-MM-DD') : ''
            item.insert_time = item.insert_time ? this.$moment.unix(item.insert_time).format('YYYY-MM-DD') : ''
          }
          this.listData = res.data.return_dict.result
          this.topData = res.data.return_dict
          this.total = res.data.total
          // 处理日期
          this.topData.create_time = this.topData.create_time ? this.$moment(this.topData.create_time * 1000).format('YYYY-MM-DD') : ''
          this.quantity_total = res.data.return_dict.quantity_total
          this.amount_total = res.data.return_dict.amount_total
          let creatTime = res.data.return_dict.create_time ? this.$moment.unix(res.data.return_dict.create_time).format('YYYY-MM-DD') : ''
          this.$set(this.topData, 'supplier', res.data.return_dict.supplier_name)
          this.$set(this.topData, 'receipt', res.data.return_dict.order_number)
          this.$set(this.topData, 'warehouse', res.data.return_dict.warehouse_name)
          this.$set(this.topData, 'data', creatTime)
          this.$set(this.topData, 'acceptor', res.data.return_dict.create_by)
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
// 表格
.tabDiv {
  .totalFont {
    font-size: 14px;
  }
  .pages {
    margin-top: 10px;
    overflow: hidden;
    line-height: 32px;
  }
}
.paddingBot40px {
  padding-bottom: 40px;
}
.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  .itemTitle {
    width: 100px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  .itemTitle::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
.marginRight30 {
  margin-right: 30px;
  span {
    color: #529dee;
  }
}
</style>
